<template>

    <b-card>
        <custom-loader :show="showLoader">
            <v-select v-if="editing" v-model="selected" @input="setSelection" appendToBody class="floating-filter w-100" :options="versions" :clearable="false" :searchable="false" label="id" :reduce="item => item">
                <template #selected-option="{id,version_timestamp}">
                    <span  class="textCutSelect">
                        {{$t('version.current')}} - {{0 > getIndexById(id)  ? versions.length === 0 ? versions.length : versions.length-1  : versions.length - getIndexById(id)-1}}
                        ({{ $dayjs(version_timestamp).format('DD.MM.YYYY HH:mm:ss') }})
                    </span>
                </template>
                <template #option="{id,version_timestamp}">
                    <span  class="textCutSelect">
                        {{$t('version.version')}} - {{versions.length - getIndexById(id)-1}}
                        ({{ $dayjs(version_timestamp).format('DD.MM.YYYY HH:mm:ss') }})
                    </span>
                </template>
            </v-select>

            <div v-if="!showLoader">
                <div class="mb-1 w-100 p-2 d-flex justify-content-end" v-if="writeOff && editing">
                    <b-button variant="primary" @click="viewReportModal">
                        {{ $t('general.view_printout') }}
                    </b-button>
                </div>

                <div class="row align-items-stretch">

                    <div class="col-12 col-md-6">
                        <div class="data-border">
                            <div class="border-text">
                                {{ $t('material.write_off.write_off_data') }}
                            </div>

                            <!-- PODATKI O ODPISU -->
                            <div class="p-1">
                                <validation-observer ref="validation">
                                    <div class="col-12">
                                        <b-form-group :label="$t('general.warehouse')">
                                            <div v-if="addObject && writeOff && editing && addObject.warehouse_id !== writeOff.warehouse_id" class="pb-1" style="text-decoration: line-through">
                                                <v-select v-model="writeOff.warehouse_id" :disabled="true" appendToBody label="name" :options="warehouses" :clearable="false" :reduce="item => item.id"/>
                                            </div>

                                            <validation-provider #default="{ errors }" :name="$t('general.warehouse')" rules="required">
                                                <v-select v-model="addObject.warehouse_id" appendToBody label="name" :options="warehouses" :clearable="false" :reduce="item => item.id" @input="updateTemporaryFileWriteOff"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12">
                                        <b-form-group>
                                            <div v-if="writeOff && editing && selectTime !== selectTimeOriginal" class="pb-1" style="text-decoration: line-through">
                                                <b-form-radio-group v-model="selectTimeOriginal" :disabled="true" :options="optionsTime"/>
                                            </div>
                                            <validation-provider #default="{ errors }" :name="$t('general.start_time')" rules="required">
                                                <b-form-radio-group v-model="selectTime" :options="optionsTime" name="select time" @change="updateTemporaryFileWriteOff"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12">
                                        <b-form-group :label="$t('general.start_date')">

                                            <div v-if="addObject && writeOff && editing && addObject.timestamp !== writeOff.timestamp" class="pb-1" style="text-decoration: line-through">
                                                <input class="form-control"
                                                       :value="formatDate(writeOff.timestamp)"
                                                       :disabled="true"
                                                />
                                            </div>

                                            <validation-provider #default="{ errors }" :name="$t('general.start_date')" rules="required">
                                                <date-picker :first-day-of-week="2" :max-date="$dayjs().format('')" v-model="addObject.timestamp" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" is24hr hide-time-header :mode="selectedMode" :is-required="true" @input="updateTemporaryFileWriteOff">
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <input class="form-control"
                                                               :value="formatDate(inputValue)"
                                                               v-on="inputEvents"
                                                        />
                                                    </template>
                                                </date-picker>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>

                                </validation-observer>

                                <div v-if="writeOff && editing && differenceInData" class="d-flex p-1 justify-content-end">
                                    <b-button variant="warning" @click="revertWriteOffDataChanges">{{ $t('general.revert') }}</b-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="data-border">
                            <div class="border-text">
                                {{ $t('material.note') }}
                            </div>
                            <!-- OPOMBA -->
                            <div class="p-1">
                                <div v-if="addObject && writeOff && editing && addObject.note !== writeOff.note" class="pb-1" style="text-decoration: line-through">
                                    <b-form-textarea v-model="writeOff.note" :disabled="true"/> <!--:debounce="1000" @update="updateTemporaryFileWriteOff"-->
                                </div>
                                <b-form-textarea v-model="addObject.note" :debounce="1000" @update="updateTemporaryFileWriteOff"/>
                            </div>
                        </div>
                    </div>

                </div>


                <div v-if="!infoProvided" class="d-flex p-2 justify-content-end">
                    <b-button variant="primary" @click="finishedEditing(false)">
                        <span class="align-middle">{{ $t('general.next_step') }}</span>
                    </b-button>
                </div>

                <div v-if="infoProvided">
                    <div class="mt-2 p-2 d-flex justify-content-between align-items-center">
                        <div>
                            <h4 class="card-title my-auto">{{ $t('general.ingredients') }}</h4>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div v-if="writeOff && editing && !this.compareItemsArray(this.addObject.items, this.writeOff.items)" class="pr-2">
                                <b-button variant="warning" @click="revertTableChanges">{{ $t('general.revert') }}</b-button>
                            </div>
                            <b-button variant="primary" @click="openModalAddProduct">{{ $t('general.add_ingredient') }}</b-button>
                        </div>
                    </div>

                    <!-- TABLE WITH ADDED WRITEOFFS -->
                    <div v-if="joinedTable && averagePrices.length !== 0" class="p-2 mb-2">
                        <Table ref="itemsTable" :columnDefs="selectedColumns" :rowData="joinedTable" @deleteRow="removeItemFromTable" @editRow="editItem"/>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="data-border">
                            <div class="border-text">
                                {{$t('material.write_off.average_price')}}
                            </div>

                            <!-- CONTENT -->
                            <div class="row p-1">
                                <div class="col-6 text-price">
                                    {{$t('material.total_price')}}:
                                </div>
                                <div class="col-6 text-price-bold text-right">
                                    {{$numberRounding(totalPrice)}}€
                                </div>
                                <!-- ALTERNATE TEXT -->
                                <div v-if="totalPriceOriginal && $numberRounding(totalPriceOriginal) !== $numberRounding(totalPrice)" class="col-12 text-right" style="color: #e74c3c; text-decoration: line-through;">
                                    {{$numberRounding(totalPriceOriginal)}}€
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex p-2 justify-content-between" v-if="!showLoader">
                        <div class="d-flex">
                            <div class="pr-2">
                                <b-button variant="danger" @click="deleteWriteOff">
                                    <span class="align-middle">{{ $t('general.delete') }}</span>
                                </b-button>
                            </div>
                            <div v-if="writeOff && editing" class="pr-2">
                                <b-button variant="warning" @click="discardChangesWriteOff">
                                    <span class="align-middle">{{ $t('general.discard') }}</span>
                                </b-button>
                            </div>
                        </div>
                        <div>
                            <b-button variant="success" @click="finishedEditing(true)" :disabled="!allowConfirm">
                                <span v-if="writeOff && editing" class="align-middle">{{ $t('general.accept') }}</span>
                                <span v-else class="align-middle">{{ $t('general.finish_write_off') }}</span>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>


            <report-preview-modal ref="writeOffReportModal" />
            <add-item-to-write-off-or-move-supply ref="addItemsToWriteOff" @addWriteOffItem="addWriteOffItem" :used-ingredients="usedIngredients" :average-prices="averagePrices"></add-item-to-write-off-or-move-supply>
        </custom-loader>
    </b-card>
</template>

<script>
    import {
        BButton,
        BCard,
        BFormGroup,
        BFormRadioGroup, BFormTextarea
        // BOverlay
    } from 'bootstrap-vue'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {required} from '@validations'
    import vSelect from 'vue-select'
    import Table from '@/views/components/Table/Table.vue'
    import {DatePicker} from 'v-calendar'
    import AddItemToWriteOffOrMoveSupply from '@/views/components/AddItemToWriteOffOrMoveSupply.vue'
    import * as Sentry from '@sentry/vue'
    import ReportPreviewModal from '@/views/components/ReportPreviewModal.vue'
    import CustomLoader from '@/components/CustomLoader.vue'
    import {getMoneyTypes, getPieceTypes, getVolumeTypes, getWeightTypes} from '@/libs/constants/ingredients.js'
    import {getOptionsTimeSelect} from '@/libs/constants/material.js'

    export default {
        components: {
            BFormTextarea,
            CustomLoader,
            ReportPreviewModal,
            AddItemToWriteOffOrMoveSupply,
            Table,
            BButton,
            BFormRadioGroup,
            vSelect,
            BFormGroup,
            ValidationProvider,
            ValidationObserver,
            BCard,
            DatePicker
        },
        data() {
            return {
                editing: false,
                apiUrl: '',
                fileName: '',
                warehouses: [],
                showLoader: false,
                writeOffId: '',
                usedIngredients: [],
                confirmed: true,
                writeOff: null,
                editingIngredientId: null,
                addObject: {
                    warehouse_id: '',
                    timestamp: new Date().toISOString(),
                    items: [],
                    note: ''
                },
                selectTime: true,
                selectTimeOriginal: true,
                totalPrice: 0,
                totalPriceOriginal: 0,
                infoProvided: false,
                averagePrices: [],
                writeOffTemporary: null,
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                required,
                versions:[],
                selected:{id:''},
                finished:false,
                allowConfirm:true
            }
        },
        computed: {
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            optionsTime() {
                return getOptionsTimeSelect()
            },
            columnDefs() {
                return [
                    {
                        headerName: this.$t('table_fields.name'),
                        editable: false,
                        field: 'ingredient_id',
                        filter: true,
                        cellRenderer: (params) => this.getFieldIngredientName(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.quantity'),
                        editable: false,
                        field: 'quantity',
                        filter: true,
                        cellRenderer: (params) => this.getFieldQuantity(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.packaging'),
                        editable: false,
                        field: 'packaging',
                        filter: true,
                        cellRenderer: (params) => this.getFieldPackaging(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.actions'),
                        editable: false,
                        filter: false,
                        sortable: false,
                        colId: 'actions',
                        cellRenderer: 'btnCellRenderer',
                        cellRendererParams: {removeObject: true},
                        minWidth: 150
                    }
                ]
            },
            columns() {
                return [
                    { headerName: this.$t('table_fields.name'),  editable: false, field: 'ingredient_id', filter: true, cellRenderer: (params) => this.getIngredientName(params.value)},
                    { headerName: this.$t('table_fields.quantity'),  editable: false, field: 'quantity', filter: true},
                    { headerName: this.$t('table_fields.packaging'),  editable: false, field: 'packaging', filter: true, cellRenderer: (params) => this.getPackaging(params.data)}
                ]
            },
            differenceInData() {
                if (!this.writeOff || !this.addObject) {
                    return false
                }

                return !(this.addObject.warehouse_id === this.writeOff.warehouse_id &&
                    this.selectTime === this.selectTimeOriginal &&
                    this.addObject.timestamp === this.writeOff.timestamp)
            },
            compareData() {
                if (!this.writeOff || !this.addObject) {
                    return false
                }

                return this.addObject.warehouse_id === this.writeOff.warehouse_id && this.compareItemsArray(this.addObject.items, this.writeOff.items)

            },

            joinedTable() {
                const resultArray = []

                const addObjectMap = new Map(this.addObject.items.map(item => [item.ingredient_id, item]))


                for (const writeOffItem of this.writeOff.items) {
                    const addObjectItem = addObjectMap.get(writeOffItem.ingredient_id)

                    if (addObjectItem) {
                        if (this.objectsDiffer(writeOffItem, addObjectItem)) {
                            resultArray.push({ ...addObjectItem, temporary_changes: 2 })
                        } else {
                            resultArray.push({ ...addObjectItem, temporary_changes: 3 })
                        }
                    } else {
                        resultArray.push({ ...writeOffItem, temporary_changes: 1 })
                    }
                }

                if (this.addObject.items.length !== 0) {
                    for (const addObjectItem of this.addObject.items) {

                        const existingIndex = resultArray.findIndex(item => item.ingredient_id === addObjectItem.ingredient_id)

                        if (existingIndex === -1) {
                            resultArray.push({ ...addObjectItem, temporary_changes: 0 })
                        }
                    }
                }

                resultArray.sort((a, b) => a.ingredient_id - b.ingredient_id)

                return resultArray.length ? resultArray : []
            },
            selectedColumns() {
                if (this.writeOff && this.writeOff.length > 0) {
                    return this.columns
                } else {
                    return this.columnDefs
                }
            },
            selectedMode() {
                if (!this.selectTime) {
                    return 'dateTime'
                } else {
                    return 'date'
                }
            }
        },
        methods: {
            compareItemsArray(array1, array2) {
                const sortItems = (a, b) => a.ingredient_id.localeCompare(b.ingredient_id)
                array1 = array1.slice().sort(sortItems)
                array2 = array2.slice().sort(sortItems)

                if (array1.length !== array2.length) {
                    return false
                }

                for (let i = 0; i < array1.length; i++) {
                    const item1 = array1[i]
                    const item2 = array2[i]

                    if (item1.ingredient_id !== item2.ingredient_id ||
                        item1.stock !== item2.stock ||
                        item1.packaging !== item2.packaging ||
                        item1.packaging_unit !== item2.packaging_unit ||
                        item1.quantity !== item2.quantity) {
                        return false
                    }
                }

                return true
            },
            arraysMatch(arr1, arr2) {
                if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
                if (arr1.length !== arr2.length) return false
                return arr1.every((item, index) => item === arr2[index])
            },
            formatDate(inputDate) {
                if (!this.selectTime) {
                    return this.dayjs(inputDate).format('dddd, DD.MM.YYYY, HH:mm')
                } else {
                    return this.dayjs(inputDate).format('dddd, DD.MM.YYYY')
                }
            },
            async revertTableChanges() {
                this.addObject.items = JSON.parse(JSON.stringify(this.writeOff.items))
                await this.updateTemporaryFileWriteOff()

                this.usedIngredients = []
                for (const item of this.addObject.items) {
                    this.usedIngredients.push(item.ingredient_id)
                }

                this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
            },
            async viewReportModal() {
                await this.$refs.writeOffReportModal.show(this.fileName, this.apiUrl)
            },
            async revertWriteOffDataChanges() {
                this.selectTime = this.selectTimeOriginal
                this.addObject.warehouse_id = this.writeOff.warehouse_id
                this.addObject.timestamp = this.writeOff.timestamp

                await this.updateTemporaryFileWriteOff()
            },
            objectsDiffer(obj1, obj2) {
                return obj1.quantity !== obj2.quantity || obj1.stock !== obj2.stock || obj1.packaging !== obj2.packaging || obj1.packaging_unit !== obj2.packaging_unit
            },
            getFieldQuantity(data) {
                return this.checkField(data, undefined, 'quantity')
            },
            getFieldIngredientName(data) {
                return this.checkField(data, this.getIngredientName, 'ingredient_id')
            },
            getFieldPackaging(data) {
                return this.checkField(data, this.getPackaging, 'packaging')
            },
            checkField(data, fn, fieldName) {
                let show = fn ? fn(data[fieldName]) : data[fieldName]

                if (fieldName === 'packaging') {
                    show = fn(data)
                }

                if (this.writeOff.items.length === 0) { // ustvarjamo nov odpis
                    return `<div>${show}</div>`
                } else if (!this.writeOff.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekta ni v originalnem arrayu
                    return `<div style="color: #2ecc71;">${show}</div>`
                } else if (this.writeOff.items.some(obj => obj.ingredient_id === data.ingredient_id) && this.addObject.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekt obstaja v originalnem arrayju in v temporary
                    const temporaryItem = this.addObject.items.find(obj => obj.ingredient_id === data.ingredient_id)
                    const originalItem = this.writeOff.items.find(obj => obj.ingredient_id === data.ingredient_id)

                    let original = fn ? fn(originalItem[fieldName]) : originalItem[fieldName]
                    let temporary = fn ? fn(temporaryItem[fieldName]) : temporaryItem[fieldName]
                    if (fieldName === 'packaging') {
                        original = fn(originalItem)
                        temporary = fn(temporaryItem)
                    }

                    if (originalItem[fieldName] !== temporaryItem[fieldName]) { // vrednosti sta dejansko drugačni
                        return `<div>
                                    <span style="color: #e74c3c; text-decoration: line-through;">${original}</span>
                                    <span style="color: #2ecc71;">${temporary}</span>
                                </span>`
                    } else { // vrednosti sta enaki
                        return `<div>${original}</div>`
                    }
                } else if (this.writeOff.items.some(obj => obj.ingredient_id === data.ingredient_id) && !this.addObject.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekt obstaja v original, ampak ne v temporary
                    const originalItem = this.writeOff.items.find(obj => obj.ingredient_id === data.ingredient_id)
                    let original = fn ? fn(originalItem[fieldName]) : originalItem[fieldName]
                    if (fieldName === 'packaging') {
                        original = fn(originalItem)
                    }

                    return `<div style="color: #e74c3c; text-decoration: line-through;">${original}</div>`
                }
            },
            async discardChangesWriteOff() {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_temporary_msg'))
                    if (!confirmDelete) return

                    await this.$http.delete(`/api/client/v1/write_offs_temporary/${this.$route.params.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    await this.$router.push({name: 'write_off'})
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async deleteWriteOff() {

                const confirmDelete = await this.$confirmDialog(this.$t('material.write_off.confirm.delete_write_off'))
                if (!confirmDelete) return

                try {

                    await this.$http.delete(`/api/client/v1/write_offs/${this.$route.params.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    await this.$router.push({name: 'write_off'})
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async updateTemporaryFileWriteOff() {

                try {
                    const timestamp = this.addObject.timestamp
                    const note = this.addObject.note

                    const items = this.addObject.items.map(obj => ({
                        stock: obj.packaging * obj.quantity,
                        ingredient_id: obj.ingredient_id,
                        packaging: obj.packaging,
                        packaging_unit: obj.packaging_unit,
                        quantity: obj.quantity
                    }))

                    const payload = {
                        'is_start_working_hour': this.selectTime,
                        'warehouse_id': this.addObject.warehouse_id,
                        timestamp,
                        items,
                        note,
                        'edited':true
                    }

                    await this.$http.post(`/api/client/v1/write_offs_temporary/${this.$route.params.id}`, payload)
                    await this.getAveragePricesForWarehouse()
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async getAveragePricesForWarehouse() {
                try {
                    const payload = {
                        'timestamp': this.addObject.timestamp
                    }

                    if (this.addObject.warehouse_id !== '') {
                        const response = await this.$http.post(`/api/client/v1/ingredients/average_price/${this.addObject.warehouse_id}`, payload)
                        this.averagePrices = response.data ?? []

                        this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
                    }
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async checkValidation() {
                return await this.$refs.validation.validate()
            },
            openModalAddProduct() {
                this.$refs.addItemsToWriteOff.open(null)
            },
            addWriteOffItem(payload, edited) {
                const itemCopy = JSON.parse(JSON.stringify(payload))

                if (edited) {
                    const index = this.addObject.items.findIndex(ele => payload.ingredient_id === ele.ingredient_id)

                    if (index > -1) { // najde objekt
                        this.addObject.items[index] = itemCopy

                        const usedIngredientsIndex = this.usedIngredients.indexOf(payload.ingredient_id)
                        if (usedIngredientsIndex > -1) {
                            this.usedIngredients[usedIngredientsIndex] = payload.ingredient_id
                        }

                        this.addObject.items.push()
                        this.$refs.itemsTable.refresh()
                    } else { // spremenili smo že obstoječo sestavino v drugo sestavino
                        const indexToRemove = this.addObject.items.findIndex(item => item.ingredient_id === this.editingIngredientId)

                        if (indexToRemove > -1) {
                            this.addObject.items.splice(indexToRemove, 1)
                        }

                        this.addObject.items.push(itemCopy)
                        this.usedIngredients.push(payload.ingredient_id)
                    }
                } else {
                    this.usedIngredients.push(payload.ingredient_id)
                    this.addObject.items.push(itemCopy)
                }

                this.updateTemporaryFileWriteOff()
                this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
            },
            getPackaging(params) {
                let factor = 1
                let type = null
                if (params.packaging_unit === 'mL' || params.packaging_unit === 'dL' || params.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === params.packaging_unit)
                } else if (params.packaging_unit === 'g' || params.packaging_unit === 'dag' || params.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === params.packaging_unit)
                } else if (params.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === params.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === params.packaging_unit)
                }

                if (!type) {
                    return
                }

                factor = type.factor

                const packagingInDisplayedUnit = params.packaging / factor

                return `${packagingInDisplayedUnit  } ${  params.packaging_unit}`
            },
            getIngredientName(value) {
                if (value && this.averagePrices) {
                    const tmp = this.averagePrices.find(x => x.id === value)

                    if (tmp) return tmp.name
                }

                return '/'
            },
            async removeItemFromTable(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    const index = this.addObject.items.findIndex(ele => ele.ingredient_id === data.ingredient_id)
                    if (index > -1) {
                        this.addObject.items.splice(index, 1)
                        this.usedIngredients.splice(index, 1)
                        this.$refs.itemsTable.refresh()
                    }
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.on_delete'))
                } finally {
                    this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
                    await this.updateTemporaryFileWriteOff()
                }
            },
            editItem(data) {
                this.editingIngredientId = data.ingredient_id
                this.$refs.addItemsToWriteOff.open(data)

                this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
            },
            calcTotalPriceWhileChanging(totalPrice, items) {
                if (items) {
                    totalPrice = 0

                    for (let i = 0; i < items.length; i++) {
                        const item = items[i]
                        const averagePrice = this.averagePrices.find(obj => obj.id === item.ingredient_id)

                        if (averagePrice) {
                            totalPrice += averagePrice.average_price * item.packaging * item.quantity
                        }
                    }
                }
                return totalPrice
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/warehouses/')
                    this.warehouses = response.data ?? []
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async finishedEditing(done) {
                const validation = this.checkValidation()

                if (validation) {
                    this.infoProvided = true
                }

                if (!validation || !done) {
                    return
                }

                if (this.addObject.items.length === 0) {
                    this.$printError(this.$t('print.error.on_empty_write_off'))
                    return
                }

                const confirmDelete = await this.$confirmDialog(this.$t('material.write_off.confirm.write_off'))
                if (!confirmDelete) return

                try {
                    this.allowConfirm = false
                    await this.$http.post(`/api/client/v1/write_offs_temporary/permanent/${this.$route.params.id}`)
                    this.allowConfirm = true
                    this.finished = true
                    this.$printSuccess(this.$t('print.success.add'))
                    this.$router.push({name: 'write_off'})
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            getIndexById(id) {
                return this.versions.findIndex(ele => ele.id === id)
            },
            async setSelection() {
                if (!this.compareData) return this.$printError(this.$t('print.error.save_before_switch'))
                await this.openEditing(this.selected, this.selected, true)
            },
            async openEditing(data, writeOff, editing) {
                await this.getAveragePricesForWarehouse()

                this.writeOff = writeOff
                this.editing = editing

                if (this.writeOff && this.editing) { // ne delamo novega odpisa
                    this.selectTimeOriginal = this.writeOff.is_start_working_hour

                    this.apiUrl = `/api/reports/v1/printout/write_off/${  data.id  }`
                    this.fileName =  `Odpis_${data.write_off_code}.pdf`

                    this.addObject.note = data.note

                    this.addObject.items = data.items
                    const exists = this.selectedColumns.some(column => column.field === 'temporary_changes')

                    if (!exists) {
                        this.selectedColumns.splice(this.selectedColumns.length - 1, 0, {
                            headerName: this.$t('table_fields.changes'),
                            editable: false,
                            field: 'temporary_changes',
                            cellRenderer: 'DisplayTemporaryEditing'
                        })
                    }                
                } else { // delamo nov odpis
                    this.addObject.timestamp = data.timestamp
                    this.writeOff.items = []
                    this.selectTimeOriginal = data.is_start_working_hour
                    this.addObject.items = []
                    this.totalPriceOriginal = undefined
                    this.addObject.note = data.note

                }

                if (data.warehouse_id !== this.$NilObjectId) {
                    this.infoProvided = true
                    this.confirmed = data.confirmed

                    this.addObject.warehouse_id = data.warehouse_id
                    await this.getAveragePricesForWarehouse()

                    this.selectTime = data.is_start_working_hour
                    this.addObject.timestamp = data.timestamp
                    this.addObject.items = data.items
                    this.totalPriceOriginal = this.calcTotalPriceWhileChanging(this.totalPriceOriginal, this.writeOff.items)
                    this.usedIngredients = this.addObject.items.map(item => item.ingredient_id)
                    this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
                }

                this.showLoader = false
            },
            async loadVersions() {
                try {
                    const res = await this.$http.get(`/api/client/v1/write_offs_history/${this.writeOffId}`)
                    this.versions = res.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                }
            }
        },
        async mounted() {
            await this.loadData()
            this.writeOffId = this.$route.params.id
            await this.loadVersions()

        },
        async beforeDestroy() {
            const items = this.addObject.items.map(obj => ({
                stock: obj.packaging * obj.quantity,
                ingredient_id: obj.ingredient_id,
                packaging: obj.packaging,
                packaging_unit: obj.packaging_unit,
                quantity: obj.quantity
            }))

            const note = this.addObject.note

            const payload = {
                'is_start_working_hour': this.selectTime,
                'warehouse_id': this.addObject.warehouse_id,
                'timestamp': this.addObject.timestamp,
                items,
                note,
                'edited':false
            }
            if (this.finished) return
            await this.$http.post(`/api/client/v1/write_offs_temporary/check/${this.writeOffId}`, payload)
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.data-border{
  border: 2px solid #646464;
  padding: 10px;
  position: relative;
}

.border-text{
  position: absolute;
  top: -12px;
  background: white;
  padding: 0 15px;
  font-weight: bold;
}

.dark-layout .border-text{
  background: #3e3e3e;
}

.text-price-bold{
  font-weight: bold;
  font-size: 17px;
}

.text-price{
  font-size: 17px;
}
</style>
